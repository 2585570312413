@import url('https://fonts.googleapis.com/css?family=Roboto');

.fl-aeropuerto {
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    font-family:  'Roboto', Helvetica, sans-serif;
    min-height: 100vh;
    &-breadcrumb {
        text-align: center;
        margin-bottom: 0.5rem;
        span {
            border-radius: 6.25rem;
            background: rgba(255, 255, 255, 0.20);
            padding: 0.5rem 1rem;
            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 12px;
            font-weight: 400;
            a {
                color: #010101;
            }
        }
    }
    &-subtitle {
        color: #FFF;
        text-align: center;
        font-family:  'Roboto', Helvetica, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .menu {
        .logo {
            padding: 0;
            svg {
                margin: 0;
            }
        }
    }
    .bar {
        background-color: white !important;
        height: 2px !important;
    }
    .menu-internal {
        &-content {
            position: relative;
            height: 100%;
            .navbar-item {
                color: #0057FF;
                font-family:  'Roboto', Helvetica, sans-serif;
                font-size: 1.5625rem;
                font-weight: 400;
                padding: 2.25rem;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: none;
                border: none;
                margin: 0;
                cursor: pointer;
                &:hover, &:focus, &:active {
                    background: none !important;
                    border: none !important;
                }
            }
            li:not(:last-child) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.30);
            }
            &-logo-aerop {
                position: absolute;
                bottom: 1.5rem;
                left: 50%;
                transform: translateX(-50%);
                max-width: 7.75rem;
            }
        }
        &.open {
            position: fixed;
            height: 100vh;
            width: 100%;
            z-index: 999;
        }
    }
    .menu-internal .menu-toggle.open .bar:nth-child(1) {
        transform: rotate(45deg) translate(5.5px, 5.5px);
    }
    .menu-internal .menu-toggle.open .bar:nth-child(3) {
        transform: rotate(-45deg) translate(5.5px, -5.5px);
    }

    &-main {
        padding: 0 1.8rem;
        margin-top: 5.44rem;
        min-height: calc(100vh - 223px);
        position: relative;
        h1 {
            color: #FFF;
            text-align: center;
            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 4.5rem;
            white-space: break-spaces;
        }
        &-btn {
            border-radius: 2.5rem;
            border: 3px solid rgba(255, 255, 255, 0.50);
            background: linear-gradient(112deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.10) 49.59%);
            box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.10);
            backdrop-filter: blur(10px);
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #FFF;

            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 1.6rem 1.13rem;
            margin-bottom: 1.44rem;
        }
        &-logoAerop {
            margin-bottom: 1.5rem;
            max-width: 7.75rem;
            margin-top: 7rem;
        }
    }
    &-arrivals {
        padding: 0 1.81rem;
        h1 {
            color: #FFF;
            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 1.75rem;
            font-weight: 400;
            margin-bottom: 1.38rem;
            margin-top: 4.12rem;
            line-height: normal;
        }
        &-button {
            padding: 0.6875rem 3.625rem;
            border-radius: 2.3125rem;
            border: 1px solid #000;
            background: rgba(255, 255, 255, 0.80);
            color: #000;
            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            align-self: center;
            margin-top: 3.12rem;
        }
    }
    &-form {
        display: flex;
        flex-direction: column;
        .field {
            margin-bottom: 1rem;
        }
        label {
            color: #FFF;
            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 1rem;
            font-weight: 400;
        }
        input {
            color: white !important;
            border-radius: 2.5rem;
            border: 1px solid #FFF;
            background: linear-gradient(301deg, rgba(241, 240, 240, 0.20) -19.36%, rgba(255, 255, 255, 0.20) 102.8%);
            backdrop-filter: blur(5px);
            &::placeholder {
                font-family:  'Roboto', Helvetica, sans-serif;
                color: rgba(255, 255, 255, 0.50);
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
    &-response {
        margin-top: 1.25rem;
        margin-bottom: 1.94rem;
        min-height: 55vh;
        max-height: 63vh;
        overflow-y: scroll;
        padding: 0 4rem;
        color: #000;
        font-family:  'Roboto', Helvetica, sans-serif;
        font-size: 1rem;
        font-weight: 400;
        white-space: pre-line;
        ul {
            list-style: inherit;
        }
        h1, h2 {
            color: #0057FF;
            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 1.5625rem;
            font-weight: 400;
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 1rem;
        }
        &-buttons {
            display: flex;
            padding: 0 3.31rem;
            justify-content: space-between;
            &-btn {
                color: #0057FF;
                border-radius: 2.3125rem;
                border: 1px solid #000;
                background: rgba(255, 255, 255, 0.50);
                padding: 0.5rem 0.6rem;
                font-size: 0.875rem;
                font-weight: 400;
                min-width: 7.5rem;
                font-family:  'Roboto', Helvetica, sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.625rem;
                path {
                    fill: #0057FF;
                }
            }
        }
    }
    &-btn-lang {
        border-radius: 2.3125rem;
        border: 1px solid rgba(255, 255, 255, 0.60);
        background: rgba(255, 255, 255, 0.20);
        padding: 0.4rem 0.3rem;
        display: flex;
        gap: 0.3rem;
        justify-content: space-between;
        align-items: center;
        color: #FFF;
        font-family:  'Roboto', Helvetica, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        &-arrow {
            margin-left: 1rem;
        }
    }
    &-btn-info {
        background: transparent;
        border: 0;
        padding: 0;
        svg {
            width: 34px;
            height: 34px;
        }
    }
    .menu-internal-content.open {
        margin-top: 6rem;
    }
    &-modal {
        position: absolute;
        width: 90%;
        top: 1.5rem;
        left: 50%;
        transform: translateX(-50%); 
        border-radius: 1.25rem;
        background: var(--gradient2, linear-gradient(170deg, #DCDCDC -9.44%, #2400B4 100%));
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
        min-height: 28rem;
        z-index: 99999999;
        &-background {
            z-index: 99999;
            background: rgba(255, 255, 255, 0.60);
            position: fixed;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
        }
        &-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1.19rem 1.5rem;
            width: 100%;
            height: 100%;
        }
        &-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
        }
        &-content {
            margin-top: 2.12rem;
            padding-right: 1.5rem;
            color: #FFF;
            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.2875rem;
            &-iscreateaccount {
                text-align: center;
                margin-bottom: 1.4rem;
                padding-right: 0;
            }
        }
        &-close {
            background: transparent;
            border: 0;
            align-self: flex-end;
        }
        &-btn {
            border-radius: 2.3125rem;
            border: 1px solid #000;
            background: rgba(255, 255, 255, 0.80);
            color: #000;
            font-family:  'Roboto', Helvetica, sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            padding: 0.5rem 1.7rem;
            align-self: center;
        }
        &-logoFlipando {
            min-width: 13rem;
            align-self: center;
        }
    }

    &-loading {
        height: 100vh !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-text {
            margin-top: 2rem;
            border-radius: 6.25rem;
            background: rgba(255, 255, 255, 0.20);
            backdrop-filter: blur(7.5px);
            padding: 0.5rem 1rem;
            max-width: 18rem;
        }
        &-center {
            align-self: center;
            text-align: center;
            p {
                color: #FFF;
                text-align: center;
                font-family:  'Roboto', Helvetica, sans-serif;
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
    &-nav {
        &-buttons {
            display: flex;
            gap: 0.5rem;
            align-self: center;
        }
        &-btn-back {
            background: transparent;
            border: 0;
            padding: 0;
            svg {
                width: 34px;
                height: 34px;
            }
        }
    }
}
.fl-aeropuerto-response-logoAero-container {
    display: flex;
    justify-content: center;
    width: 100%;
    .fl-aeropuerto-main-logoAerop {
        margin-top: 1.8rem;
        margin-bottom: 1.8rem;
    }
}
.fl-aeropuerto-main-logoAero-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.aeropuerto-desktop {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    h1 {
        color: #FFF;
        text-align: center;
        font-family:  'Roboto', Helvetica, sans-serif;
        font-size: 2.65994rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }
    h2 {
        display: flex;
        align-items: center;
        color: #FFF;
        text-align: center;
        font-family:  'Roboto', Helvetica, sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
    }
    p {
        color: #FFF;
        font-family:  'Roboto', Helvetica, sans-serif;  
        font-size: 2.3475rem;
        font-weight: 300;
        white-space: break-spaces;
        margin-bottom: 2rem;
        line-height: normal;
    }
    img {
        width: 10rem;
    }
}
.navbar-item {
    background: transparent;
}

@media (min-width: 768px) {
    .menu-internal {
        height: 100vh;
        min-width: 224px;
        position: fixed;
        top: 0;
        left: 0;
        background-color: var(--white);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }
    .menu-toggle {
        display: none !important;
    }
    .logo-desktop {
        display: block !important;
    }
    .logo-mobile {
        display: none !important;
    }
}


.navbar {
    background: transparent !important;
    border: 0;
    z-index: 0;
}

.menu-internal {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.open {
        display: block;
        height: 100vh;
    }
    &-content {
        display: none;
    }
    &-content.open {
        display: block;
        .menu-internal-sup {
            margin-bottom: 4rem;
        }
    }

    .logo-desktop {
        display: none;
    }
    .logo-mobile {
        display: block;
    }
    .menu-toggle {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 24px;
        height: 18px;
        cursor: pointer;
        z-index: 100;
        margin-bottom: 20px;
        position: absolute;
        top: 2rem;
        right: 2rem;

        .bar {
            width: 24px;
            height: 3px;
            background-color: var(--text-color-over-dark-primary);
            transition: transform 0.3s, opacity 0.3s;
        }

        &.open {
            .bar:nth-child(1) {
                transform: rotate(45deg) translate(5px, 5px);
            }
            .bar:nth-child(2) {
                opacity: 0;
            }
            .bar:nth-child(3) {
                transform: rotate(-45deg) translate(5px, -5px);
            }
        }
    }
    .logo:hover {
        background-color: transparent;
    }
    &-sup {
        ul {
            margin-top: 3rem;
            li:not(:first-child) {
                margin-bottom: 16px;
                a, span {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: var(--text-color-over-dark-primary);
                    padding: 11px 24px;
                }
                a:hover, a:active, a:focus {
                    color: var(--primary);
                    background-color: transparent;
                }
            }
        }
        &-user {
            background: rgba(238, 238, 238, 0.7);
            border-radius: 1.5rem;
            margin-bottom: 38px + 15px;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: var(--text-color-over-dark-primary);
            &-img {
                margin-right: 1rem;
            }
            a:hover, a:active, a:focus {
                background: rgba(238, 238, 238, 0.7) !important;
                border-radius: 1.5rem !important;
                color: var(--text-color-over-dark-primary);
            }
            .sb-avatar {
                margin-right: 0.5rem;
            }
            &-team {
                display: block;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
            }
            &-credits {
                display: block;
                color: rgba(51, 51, 51, 0.90);
                font-size: 0.625rem;
                font-style: normal;
                font-weight: 400;
                span {
                    font-weight: bold;
                }
            }
        }
    }
    .navbar-item {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        border-radius: 15px !important;
        svg {
            margin-right: 8px;
        }
        &:hover {
            background-color: #fafafa !important;
        }
    }
    .active {
        font-weight: bold;
        border-radius: 1rem !important;
        background: #EEE !important;
    }
    &-logout {
        font-weight: 500;
        font-size: 14px;
        text-align: left;
        color: var(--text-color-over-dark-primary-90);
        justify-content: flex-start;
        width: 100% !important;
        border: 0 !important;
        border-radius: 0 !important;
        svg {
            margin-right: 8px;
        }
    }
    &-subscription {
        border-radius: 15px;
        background: #EEE;
        color: #333 !important;
    }
    .team-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-setting {
            z-index: 99;
            padding: 0 !important;
            line-height: 0 !important;
            border: none !important;
            background-color: transparent;
            padding: 0;
            cursor: pointer;
            svg {
                margin: 0;
            }
        }
        &-general {
            display: flex;
            align-items: center;
        }
    }
}