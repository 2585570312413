.social-share-container {
    background-color: white;
    border-radius: 1.25rem;
    position: fixed;
    transition: transform 0.7s, opacity 0.3s;
    transform: translateY(100%);
    opacity: 0; 
    pointer-events: none;
    width: 100%;
    &.visible {
      transform: translateY(10%);
      bottom: 0;
      opacity: 1; 
      pointer-events: auto;
      .social-share-container-content {
        display: flex;
      }
    }
    &-content {
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 2rem;
        .closing-rectangle {
            border-radius: 0.3125rem;
            background: #B1B5D3;
            width: 3.625rem;
            height: 0.25rem;
            margin-top: 0.3rem;
        }
        &-buttons {
            display: flex;
            width: 100%;
            gap: 1.5rem;
            position: relative;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding: 2rem 2.3rem;
            &-btn {
                background: transparent;
                border: 0;
                padding: 0;
            }
            button {
                display: flex;
                flex-direction: column;
                align-items: center;
                span {
                    color: #010101;
                    text-align: center;
                    font-family:  'Roboto', Helvetica, sans-serif;
                    font-size: 0.625rem;
                    font-weight: 400;
                }
            }
        }
    }
}
